import { useEffect, useMemo, useState, useCallback } from 'react';
import * as anchor from '@project-serum/anchor';

import styled from 'styled-components';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { PublicKey } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletDialogButton } from '@solana/wallet-adapter-material-ui';
import {
  awaitTransactionSignatureConfirmation,
  CandyMachineAccount,
  CANDY_MACHINE_PROGRAM,
  getCandyMachineState,
  mintOneToken,
} from './candy-machine';
import { AlertState } from './utils';
import { Header } from './Header';
import { MintButton } from './MintButton';
import { GatewayProvider } from '@civic/solana-gateway-react';

const ConnectButton = styled(WalletDialogButton)``;

const MintContainer = styled.div``; // add your owns styles here

export interface HomeProps {
  candyMachineId?: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  txTimeout: number;
  rpcHost: string;
}

const handleMove = (e: { clientX: any; }) => {
  //console.log(e.clientX);
  //console.log('dsfdfsa' + window.innerWidth);

  var cursorPoint = e.clientX;
  var pageWidth = window.innerWidth;

  var percentages = (cursorPoint / pageWidth * 10000) / 100;
  console.log(percentages)


  if (percentages <  10 ) {
    document.getElementById('camera').setAttribute('src', '/image/camera-1.png');
  }

  else if (percentages >  10 && percentages < 18 ) {
    document.getElementById('camera').setAttribute('src', '/image/camera-2.png');
  }

  else if (percentages >  18 && percentages < 27 ) {
    document.getElementById('camera').setAttribute('src', '/image/camera-3.png');
  }

  else if (percentages >  27 && percentages < 36 ) {
    document.getElementById('camera').setAttribute('src', '/image/camera-4.png');
  }

  else if (percentages >  36 && percentages < 45 ) {
    document.getElementById('camera').setAttribute('src', '/image/camera-5.png');
  }

  else if (percentages >  45 && percentages < 54 ) {
    document.getElementById('camera').setAttribute('src', '/image/camera-6.png');
  }

  else if (percentages >  54 && percentages < 63 ) {
    document.getElementById('camera').setAttribute('src', '/image/camera-7.png');
  }

  else if (percentages >  63 && percentages < 72 ) {
    document.getElementById('camera').setAttribute('src', '/image/camera-8.png');
  }

  else if (percentages >  72 && percentages < 81 ) {
    document.getElementById('camera').setAttribute('src', '/image/camera-9.png');
  }

  else if (percentages >  81 && percentages < 90 ) {
    document.getElementById('camera').setAttribute('src', '/image/camera-10.png');
  }

  else if (percentages >  90 && percentages < 100 ) {
    document.getElementById('camera').setAttribute('src', '/image/camera-11.png');
  }

}


const eyeScan = () => {
  document.getElementById('eye-scan-image').style.display= 'block';
}

const eyeScanOut = () => {
  document.getElementById('eye-scan-image').style.display= 'none';
}

const eyeScanClick= () => {
  document.getElementById('eye-scan-gif').style.display= 'block';
  (document.getElementById('sound') as HTMLMediaElement).play();
  setTimeout(function () {
    document.getElementById('door-1').style.top= '-50px';
    document.getElementById('door-2').style.top= '510px';
    document.getElementById('inside-door').style.display= 'block';
  }, 5500);
}

const Home = (props: HomeProps) => {
  const [isUserMinting, setIsUserMinting] = useState(false);
  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: '',
    severity: undefined,
  });

  const rpcUrl = props.rpcHost;
  const wallet = useWallet();

  const anchorWallet = useMemo(() => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }

    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as anchor.Wallet;
  }, [wallet]);

  const refreshCandyMachineState = useCallback(async () => {
    if (!anchorWallet) {
      return;
    }

    if (props.candyMachineId) {
      try {
        const cndy = await getCandyMachineState(
          anchorWallet,
          props.candyMachineId,
          props.connection,
        );
        console.log(JSON.stringify(cndy.state, null, 4));
        setCandyMachine(cndy);
      } catch (e) {
        console.log('There was a problem fetching Candy Machine state');
        console.log(e);
      }
    }
  }, [anchorWallet, props.candyMachineId, props.connection]);

  const onMint = async () => {
    try {
      setIsUserMinting(true);
      document.getElementById('#identity')?.click();
      if (wallet.connected && candyMachine?.program && wallet.publicKey) {
        const mintTxId = (
          await mintOneToken(candyMachine, wallet.publicKey)
        )[0];

        let status: any = { err: true };
        if (mintTxId) {
          status = await awaitTransactionSignatureConfirmation(
            mintTxId,
            props.txTimeout,
            props.connection,
            true,
          );
        }

        if (status && !status.err) {
          setAlertState({
            open: true,
            message: 'Congratulations! Mint succeeded!',
            severity: 'success',
          });
        } else {
          setAlertState({
            open: true,
            message: 'Mint failed! Please try again!',
            severity: 'error',
          });
        }
      }
    } catch (error: any) {
      let message = error.msg || 'Minting failed! Please try again!';
      if (!error.msg) {
        if (!error.message) {
          message = 'Transaction Timeout! Please try again.';
        } else if (error.message.indexOf('0x137')) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf('0x135')) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          window.location.reload();
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: 'error',
      });
    } finally {
      setIsUserMinting(false);
    }
  };

  useEffect(() => {
    refreshCandyMachineState();
  }, [
    anchorWallet,
    props.candyMachineId,
    props.connection,
    refreshCandyMachineState,
  ]);

  return (
    <div className="container" onMouseMove={handleMove}>
      <div className="camera-wrap">
        <div className="gray-box"></div>
        <img src="/image/bg-image.png" className="bg-image" alt=""/>

        <audio id="sound" src="/sound/sound.mp3"/>

        <img src="/image/door-1.png" id="door-1" alt=""/>
        <img src="/image/door-2.png" id="door-2" alt=""/>
        <div className="bg-white"></div>

        <img id="camera" src="" className="camera" alt=""/>

        <div id="inside-door">

          <main>
            <img src="/image/logo.png" className="logo" alt=""/>
            {!wallet.connected ? (
              <div>
            <ConnectButton>Connect Wallet</ConnectButton>
            </div>
          ) : (
            <>
              <Header candyMachine={candyMachine} />
              <MintContainer>
                {candyMachine?.state.isActive &&
                candyMachine?.state.gatekeeper &&
                wallet.publicKey &&
                wallet.signTransaction ? (
                  <GatewayProvider
                    wallet={{
                      publicKey:
                        wallet.publicKey ||
                        new PublicKey(CANDY_MACHINE_PROGRAM),
                      //@ts-ignore
                      signTransaction: wallet.signTransaction,
                    }}
                    gatekeeperNetwork={
                      candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                    }
                    clusterUrl={rpcUrl}
                    options={{ autoShowModal: false }}
                  >
                    <MintButton
                      candyMachine={candyMachine}
                      isMinting={isUserMinting}
                      onMint={onMint}
                    />
                  </GatewayProvider>
                ) : (
                  <MintButton
                    candyMachine={candyMachine}
                    isMinting={isUserMinting}
                    onMint={onMint}
                  />
                )}
              </MintContainer>
            </>
          )}
          </main>

        </div>

        <div className="eye-scan-box" onMouseOver={eyeScan} onMouseOut={eyeScanOut} onClick={eyeScanClick}></div>
        <img src="/image/eye-scan.png" id="eye-scan-image" alt=""/>
        <img src="/image/eye-scan-gif.gif" id="eye-scan-gif" alt=""/>

      </div>
      <div className="info">
        <h1>TIP: Enter Mint Room through the Eye Scan 3000</h1>
        <h1>You do not need to refresh the window when the mint is live</h1>
      </div>
      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Home;
